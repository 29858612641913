<!-- 注销第一步 -->
<template>
  <div class="cancellation">
    <p class="indent">
      <strong>
        在提交注销申请前，请仔细确认以下须知
      </strong>
    </p>
    <p>1、账号处于正常使用状态且在1年内无任何被限制记录。</p>
    <p>2、本账号所涉交易业务已全部完成，且不存在任何纠纷。</p>
    <p>3、账号不涉及任何纠纷，包括投诉、举报或被投诉、被举报等情形。</p>
    <p>4、账号注销后，将匿名化或者删除您的所有个人信息，且不可恢复。</p>
    <p>5、账号注销后，您将无法再使用此账号登陆易鑫金融PC端、m站、app。</p>

    <div class="line"></div>
    <p class="nextInfo">点击“下一步”，表示用户已确认以上须知，确定需要注销</p>
    <div class="btn" @click="next">
      下一步
    </div>
  </div>
</template>

<script>
export default {
  name: "cancellation",
  methods: {
    next () {
      this.$router.push({ name: "CancellationSecond" });
    }
  }
};
</script>
<style lang="scss" scoped>
.cancellation {
  background: #fff;
  line-height: 0.3rem;
  text-indent: 0.15rem;
  padding: 0rem 0.18rem 1rem;
  .title {
    line-height: 0.36rem;
    font-size: x-large;
    margin-top: 0.2rem;
  }
  .indent {
    // text-indent: 0.36rem;
    margin-top: 0.2rem;
  }
  .line {
    height: 0.02rem;
    background: #f1f2f6;
    margin: 0.25rem 0rem 0.15rem;
  }
  .nextInfo {
    color: #8d8d91;
  }
  .btn {
    background-image: linear-gradient(to right, #ff8f6e, #fd4b4b);
    border-radius: 0.29rem;
    height: 0.54rem;
    margin: 0.25rem auto;
    text-align: center;
    color: white;
    text-align: center;
    line-height: 0.54rem;
    text-indent: 0rem;
  }
}
</style>
